import ContactSection from '../Home/components/ContactSection';

function ContactUs() {
  return (
    <div className="mt-16">      
      <ContactSection />     
    </div>
  );
}

export default ContactUs;
